import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withNamespaces, Trans } from 'react-i18next';
import {Link, Redirect} from 'react-router-dom';
import * as action from '../../store/actions/action';
import query from '../../utils/query';
import { logoWhite, support } from '../../config';
import { getGravatar } from '../../utils/utils';

import 'react-toastify/dist/ReactToastify.css';
import {removeStorage} from "../../utils/storage";

class MobileHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      messages: 0,
      logoutStatus: false,
      dropdownDisable: false,
    }
    this.logout = this.logout.bind(this);
  }

  componentDidMount() {
    this.getUnReadMessages();
  }

  async getUnReadMessages() {
    const json = await query('GET', '/v1/messages/unread-messages');

    if (json.success) {
      this.setState({
        messages: json.messages,
      });
    }
  }

  openAside = () => {
    this.props.onOpenAside();
  };

  defaultPhoto = (e) => {
    e.target.src = '/images/avatar2.png';
  };

  logout(e) {
    e.preventDefault();
    query('POST', '/v1/auth/logout').then(() => {
      this.props.onLogout();
      this.setState({ logoutStatus: true });
      removeStorage('login');
    });
  }
  openMenu = () => {
    this.setState({dropdownDisable: false});
  }
  closeMenu = () => {
    this.setState({dropdownDisable: true});
  }

  render() {
    const { account } = this.props;
    if (account && account.isLoggedIn === false) {
      return <br />;
    }

    const { messages } = this.state;
    const { permissions, user } = account;
    const { profilePhoto, email } = user;

    const messagesPermissions = permissions && permissions.messages ? permissions.messages : null;
    const accountPermissions = permissions && permissions.account ? permissions.account : null;
    const billingPermissions = permissions && permissions.billing ? permissions.billing : null;

    const helpPermissions = permissions && permissions.help ? permissions.help : null;
    const faqPermissions = permissions && permissions.faq ? permissions.faq : null;
    const bugReportPermissions = permissions && permissions['bug-report'] ? permissions['bug-report'] : null;
    const featureRequestPermissions = permissions && permissions['feature-request'] ? permissions['feature-request'] : null;
    const startupGuidePermissions = permissions && permissions['startup-guide'] ? permissions['startup-guide'] : null;
    const firstLoginPermissions = permissions && permissions['first-login'] ? permissions['first-login'] : null;
    const uiSamplesPermissions = permissions && permissions['ui-samples'] ? permissions['ui-samples'] : null;

    return (
      <div className="align-items-center d-flex justify-content-between mobile-header">
        <div className="align-items-center d-flex">
          <button className="menu-button" onClick={this.openAside} type="button">
            <i className="icon-menu" />
          </button>
        </div>
        <figure className="logo">
          <img src={logoWhite} alt="logo" />
        </figure>
        <div className="align-items-center d-flex">
          <div className="icon-alarm">
            <i className="icon-bell-o" />
          </div>
          <div className="profile dropdown">
            <div
              className="dropdown-button dropdown-toggle"
              id="dropdownMenuLink"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
              onMouseEnter={this.openMenu}
            >
              <img
                onError={this.defaultPhoto.bind(this)}
                className="image-user"
                src={ profilePhoto || getGravatar(email) }
                alt="Profile avatar"
              />
            </div>
            <div className="dropdown-list" aria-labelledby="dropdownMenuLink" style={this.state.dropdownDisable ? {display: 'none'} : {}}>
              {
                accountPermissions &&
                <Link to="/account" className="dropdown-item" onClick={this.closeMenu}>
                  <Trans i18nKey="header.ACCOUNT">Your Account</Trans>
                </Link>
              }
              {
                billingPermissions &&
                <Link to="/billing" className="dropdown-item" onClick={this.closeMenu}>
                  <Trans i18nKey='header.BILLING'>Billing</Trans>
                </Link>
              }
              {
                (accountPermissions || billingPermissions) &&
                <hr />
              }

              {
                messagesPermissions &&
                  <>
                    <Link to="/messages" className="dropdown-item" onClick={this.closeMenu}>
                      <Trans i18nKey='header.MESSAGES'>Messages</Trans>
                      {
                        messages ?
                          <span className="badge badge-pill badge-danger" id="message-count-xs">
                        {messages}
                      </span>
                          : null
                      }
                    </Link>

                    <hr/>
                  </>
              }

              {
                support &&
                <>
                  <a href={support} className="dropdown-item" target="_blank">
                    <Trans i18nKey='header.SUPPORT'>Support</Trans>
                  </a>
                  <hr />
                </>
              }

              {
                helpPermissions &&
                <Link to="/help" className="dropdown-item">
                  <Trans i18nKey='header.HELP'>Help</Trans>
                </Link>
              }
              {
                faqPermissions &&
                <Link to="/faq" className="dropdown-item">
                  <Trans i18nKey='header.FAQ'>FAQ</Trans>
                </Link>
              }
              {
                bugReportPermissions &&
                <Link to="/bug-report" className="dropdown-item">
                  <Trans i18nKey='header.BUG_REPORT'>Bug Report</Trans>
                </Link>
              }
              {
                featureRequestPermissions &&
                <Link to="/feature-request" className="dropdown-item">
                  <Trans i18nKey='header.FEATURE_REQUEST'>Feature Request</Trans>
                </Link>
              }
              {
                (helpPermissions || faqPermissions || bugReportPermissions || featureRequestPermissions) &&
                <hr />
              }

              {
                startupGuidePermissions &&
                <Link to="/startup-guide" className="dropdown-item">
                  <Trans i18nKey='header.STARTUP_GUIDE'>Startup Guide</Trans>
                </Link>
              }
              {
                firstLoginPermissions &&
                <Link to="/first-login" className="dropdown-item">
                  <Trans i18nKey='header.FIRST_LOGIN'>First Login</Trans>
                </Link>
              }
              {
                uiSamplesPermissions &&
                <Link to="/sample" className="dropdown-item">
                  <Trans i18nKey='header.UI_SAMPLES'>UI Samples</Trans>
                </Link>
              }
              {
                (startupGuidePermissions || uiSamplesPermissions) &&
                <hr />
              }

              <div className="dropdown-item cursor-pointer" onClick={this.logout} role="button" onKeyPress={this.logout} tabIndex={0}>
                <Trans i18nKey="header.LOGOUT">Log Out</Trans>
              </div>
            </div>
          </div>
        </div>
        {this.state.logoutStatus && <Redirect to='/'/>}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  account: state.accountReducer,
  theme: state.themeReducer,
});

const mapDispatchToProps = (dispatch) => ({
  onLogout: () => dispatch({ type: action.LOGOUT_USER }),
  onOpenAside: () => dispatch({ type: action.OPEN_ASIDE }),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withNamespaces('translations')(MobileHeader));
