import momentStandard from 'moment/min/moment-with-locales';
import momentJalali from 'moment-jalaali';
import momentHijri from 'moment-hijri';
import momentTimezone from 'moment-timezone';
import { getFromStorage } from './storage';
import { getLanguage, getShortLocale } from './localeTools';

function getMomentHijriLocale() {
  const symbolMap = {
    1: '١',
    2: '٢',
    3: '٣',
    4: '٤',
    5: '٥',
    6: '٦',
    7: '٧',
    8: '٨',
    9: '٩',
    0: '٠',
  };

  const numberMap = {
    '١': '1',
    '٢': '2',
    '٣': '3',
    '٤': '4',
    '٥': '5',
    '٦': '6',
    '٧': '7',
    '٨': '8',
    '٩': '9',
    '٠': '0',
  };

  return {
    months: 'يناير_فبراير_مارس_أبريل_مايو_يونيو_يوليو_أغسطس_سبتمبر_أكتوبر_نوفمبر_ديسمبر'.split('_'),
    monthsShort: 'يناير_فبراير_مارس_أبريل_مايو_يونيو_يوليو_أغسطس_سبتمبر_أكتوبر_نوفمبر_ديسمبر'.split(
      '_',
    ),
    weekdays: 'الأحد_الإثنين_الثلاثاء_الأربعاء_الخميس_الجمعة_السبت'.split('_'),
    weekdaysShort: 'أحد_إثنين_ثلاثاء_أربعاء_خميس_جمعة_سبت'.split('_'),
    weekdaysMin: 'ح_ن_ث_ر_خ_ج_س'.split('_'),
    weekdaysParseExact: true,
    longDateFormat: {
      LT: 'HH:mm',
      LTS: 'HH:mm:ss',
      L: 'iDD/iMM/iYYYY',
      LL: 'iD iMMMM iYYYY',
      LLL: 'iD iMMMM iYYYY HH:mm',
      LLLL: 'dddd iD iMMMM iYYYY HH:mm',
    },
    meridiemParse: /ص|م/,
    isPM(input) {
      return input === 'م';
    },
    meridiem(hour) {
      if (hour < 12) {
        return 'ص';
      }
      return 'م';
    },
    calendar: {
      sameDay: '[اليوم على الساعة] LT',
      nextDay: '[غدا على الساعة] LT',
      nextWeek: 'dddd [على الساعة] LT',
      lastDay: '[أمس على الساعة] LT',
      LAST_WEEK: 'dddd [على الساعة] LT',
      sameElse: 'L',
    },
    relativeTime: {
      future: 'في %s',
      past: 'منذ %s',
      s: 'ثوان',
      ss: '%d ثانية',
      m: 'دقيقة',
      mm: '%d دقائق',
      h: 'ساعة',
      hh: '%d ساعات',
      d: 'يوم',
      dd: '%d أيام',
      M: 'شهر',
      MM: '%d أشهر',
      y: 'سنة',
      yy: '%d سنوات',
    },
    preparse(string) {
      return string.replace(/[١٢٣٤٥٦٧٨٩٠]/g, (match) => numberMap[match]).replace(/،/g, ',');
    },
    postformat(string) {
      return string.replace(/\d/g, (match) => symbolMap[match]).replace(/,/g, '،');
    },
    week: {
      dow: 0, // Sunday is the first day of the week.
      doy: 6, // The week that contains Jan 6th is the first week of the year.
    },
    iMonths: 'محرم_صفر_ربيع الأول_ربيع الثاني_جمادى الأولى_جمادى الآخرة_رجب_شعبان_رمضان_شوال_ذو القعدة_ذو الحجة'.split(
      '_',
    ),
    iMonthsShort: 'محرم_صفر_ربيع ١_ربيع ٢_جمادى ١_جمادى ٢_رجب_شعبان_رمضان_شوال_ذو القعدة_ذو الحجة'.split(
      '_',
    ),
  };
}

export default function moment(...props) {
  let moment = momentStandard;
  // set locale
  moment.locale(getLanguage());
  const calendar = getFromStorage('calendar');

  if (calendar === 'jalali') {
    const usePersianDigits = getShortLocale() === 'fa';
    momentJalali.loadPersian({
      dialect: 'persian-modern',
      usePersianDigits,
    });
    moment = momentJalali;
  } else if (calendar === 'hijri') {
    // important: to set the locale for hijri months
    momentHijri.locale(getLanguage(), getMomentHijriLocale());
    moment = momentHijri;
  }

  // set timezone
  const timezone = getFromStorage('timezone');
  if (timezone) {
    const offset = momentTimezone.tz(timezone).utcOffset();
    return moment(...props).utcOffset(offset);
  }

  return moment(...props);
}
