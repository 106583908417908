import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withNamespaces, Trans } from 'react-i18next';
import {Link, Redirect} from 'react-router-dom';
import * as action from '../../store/actions/action';
import query from '../../utils/query';
import { getGravatar } from '../../utils/utils';
import { support } from '../../config';
import Notification from '../../pages/partials/Notification';

import 'react-toastify/dist/ReactToastify.css';
import { removeStorage } from '../../utils/storage';

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      logoutStatus: false,
      messages: 0,
    }
    this.logout = this.logout.bind(this);
  }

  componentDidMount() {
    this.getUnReadMessages();
  }

  async getUnReadMessages() {
    const json = await query('GET', '/v1/messages/unread-messages');

    if (json.success) {
      this.setState({
        messages: json.messages,
      });
    }
  }

  defaultPhoto = (e) => {
    e.target.src = '/images/avatar2.png';
  };

  logout(e) {
    e.preventDefault();
    query('POST', '/v1/auth/logout').then(() => {
      this.props.onLogout();
      this.setState({ logoutStatus: true });
      removeStorage('login');
    });
  }

  render() {
    const { account } = this.props;
    const { permissions } = account;

    const { messages } = this.state;

    if (account && account.isLoggedIn === false) {
      return <br />;
    }

    const messagesPermissions = permissions && permissions.messages ? permissions.messages : null;
    const accountPermissions = permissions && permissions.account ? permissions.account : null;
    const billingPermissions = permissions && permissions.billing ? permissions.billing : null;

    const helpPermissions = permissions && permissions.help ? permissions.help : null;
    const faqPermissions = permissions && permissions.faq ? permissions.faq : null;
    const bugReportPermissions = permissions && permissions['bug-report'] ? permissions['bug-report'] : null;
    const featureRequestPermissions = permissions && permissions['feature-request'] ? permissions['feature-request'] : null;
    const startupGuidePermissions = permissions && permissions['startup-guide'] ? permissions['startup-guide'] : null;
    const firstLoginPermissions = permissions && permissions['first-login'] ? permissions['first-login'] : null;
    const uiSamplesPermissions = permissions && permissions['ui-samples'] ? permissions['ui-samples'] : null;

    return (
      <>
        <div className="main-header m-3">
          <div className="main-header-flex">
            <div className="alarmContainer">
              {
                messagesPermissions &&
                <Link to="/messages">
                  <div className="icon-alarm">
                    <i className="icon-bell-o" />
                  </div>
                  {
                    messages ?
                      <span className="badge badge-pill badge-primary" id="message-count">
                            {messages}
                          </span>
                      : null
                  }
                </Link>
              }
            </div>

            <div className="profile dropdown">
              <div
                className="dropdown-button dropdown-toggle"
                id="dropdownMenuLink"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <img
                  onError={this.defaultPhoto.bind(this)}
                  className="image-user"
                  src={
                    account.user.profilePhoto || getGravatar(account.user.email)
                  }
                  alt="Profile avatar"
                />
                <span className="description">
                  <p className="name">
                    {account.user.preferredName || account.user.firstName}
                  </p>
                  <p className="work">
                    <Trans i18nKey="header.MANAGER">Manager</Trans>
                  </p>
                  <i className="icon-angle-down" />
                </span>
              </div>
              <div className="dropdown-list" aria-labelledby="dropdownMenuLink">
                {
                  accountPermissions &&
                    <Link to="/account" className="dropdown-item">
                      <Trans i18nKey="header.ACCOUNT">Your Account</Trans>
                    </Link>
                }
                {
                  billingPermissions &&
                    <Link to="/billing" className="dropdown-item">
                      <Trans i18nKey='header.BILLING'>Billing</Trans>
                    </Link>
                }
                {
                  (accountPermissions || billingPermissions) &&
                    <hr />
                }

                {
                  support &&
                    <>
                      <a href={support} className="dropdown-item" target="_blank">
                        <Trans i18nKey='header.SUPPORT'>Support</Trans>
                      </a>
                      <hr />
                    </>
                }

                {
                  helpPermissions &&
                  <Link to="/help" className="dropdown-item">
                    <Trans i18nKey='header.HELP'>Help</Trans>
                  </Link>
                }
                {
                  faqPermissions &&
                  <Link to="/faq" className="dropdown-item">
                    <Trans i18nKey='header.FAQ'>FAQ</Trans>
                  </Link>
                }
                {
                  bugReportPermissions &&
                  <Link to="/bug-report" className="dropdown-item">
                    <Trans i18nKey='header.BUG_REPORT'>Bug Report</Trans>
                  </Link>
                }
                {
                  featureRequestPermissions &&
                  <Link to="/feature-request" className="dropdown-item">
                    <Trans i18nKey='header.FEATURE_REQUEST'>Feature Request</Trans>
                  </Link>
                }
                {
                  (helpPermissions || faqPermissions || bugReportPermissions || featureRequestPermissions) &&
                  <hr />
                }

                {
                  startupGuidePermissions &&
                  <Link to="/startup-guide" className="dropdown-item">
                    <Trans i18nKey='header.STARTUP_GUIDE'>Startup Guide</Trans>
                  </Link>
                }
                {
                  firstLoginPermissions &&
                  <Link to="/first-login" className="dropdown-item">
                    <Trans i18nKey='header.FIRST_LOGIN'>First Login</Trans>
                  </Link>
                }
                {
                  uiSamplesPermissions &&
                  <Link to="/sample" className="dropdown-item">
                    <Trans i18nKey='header.UI_SAMPLES'>UI Samples</Trans>
                  </Link>
                }
                {
                  (startupGuidePermissions || uiSamplesPermissions) &&
                  <hr />
                }

                <div className="dropdown-item cursor-pointer moreBtn" onClick={this.logout} role="button" onKeyPress={this.logout} tabIndex={0}>
                  <Trans i18nKey="header.LOGOUT">Log Out</Trans>
                </div>
              </div>
            </div>
          </div>

        </div>
        {this.state.logoutStatus && <Redirect to='/'/>}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  account: state.accountReducer,
});

const mapDispatchToProps = (dispatch) => ({
  onLogout: () => dispatch({ type: action.LOGOUT_USER }),
});

export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces('translations')(Header));
