import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withNamespaces, Trans } from 'react-i18next';
import Copyright from '../../../components/common/Copyright';
import * as action from '../../../store/actions/action';
import { logo, placeholderSideImage, sideImage, availableLanguages } from '../../../config';
import query from '../../../utils/query';
import { removeStorage } from "../../../utils/storage";
import { InputValidator, Input, Select  } from '../../../components/elements';
import { setDirectionClass, setLanguageClass} from '../../../utils/utils';
import {setLanguage, getShortLocale, getDirection} from '../../../utils/localeTools';
import LoadingButton from "../../../components/elements/LoadingButton/LoadingButton";
import momentTZ from 'moment-timezone';
import Form from "react-bootstrap/Form";

const host = `${window.location.protocol}//${window.location.host}`;

class WithoutActiveOrganization extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      formRegister: {
        organizationName: '',
        country: '',
        countryValue: '',
        countryChange: false,
        address: '',
        city: '',
        state: '',
        hasState: false,
        stateChange: false,
        zipcode: '',
        zipcodeRegex: '',
        hasZipcode: false,
        timezone: '',
        hasTimezone: false,
        timezoneChange: false,
        supported: true,
      },
      firstScreen: true,
      finalScreen: false,
      timezones: [],
      countries: [],
      stateOptions: [],
      catOptions: [],
      applyLoading: false,
      touched: false,
      errors: {
        cat: true,
        city: true,
        state: false,
        zipcode: false,
        address: true,
        organizationName: true,
      },
    };
    this.logout = this.logout.bind(this);
    this.validateOnChange = this.validateOnChange.bind(this);
    this.onSubmitRegister = this.onSubmitRegister.bind(this);
    this.countryChange = this.countryChange.bind(this);
    this.getCatValue = this.getCatValue.bind(this);
  }

  componentDidMount() {
    const { account } = this.props;

    setLanguage(account.user.defaultLanguage);
    setDirectionClass(getDirection(account.user.defaultLanguage));
    setLanguageClass(account.user.defaultLanguage);

    this.setState({ isLoading: true });
    query('GET', '/v1/countries')
      .then(async (json) => {
        let countries = [];

        if (json.countries.length > 0) {
          countries = json.countries;
        }
        this.setState(state => ({
          isLoading: false,
          countries,
          firstScreen: true,
        }));

        if (json.countryIsoAlpha !== '') {
          this.countryChange(
            json.countryIsoAlpha,
            null,
            {
              value: json.countryIsoAlpha,
              label: json.countryName,
            }
          );
        }
      });
  }

  logout(e) {
    e.preventDefault();
    query('POST', '/v1/auth/logout').then(() => {
      this.props.onLogout();
      this.setState({ logoutStatus: true });
      removeStorage('login');
    });
  }

  validateOnChange = (value, event, error) => {
    const { errors, formRegister } = this.state;
    const name = event.target.name;
    errors[name] = error;
    formRegister[name] = value;
    this.setState({ errors, formRegister });
  };

  onSubmitRegister = async (e) => {
    e.preventDefault();
    const { t } = this.props;
    const { formRegister, errors } = this.state;
    this.setState({touched: true});

    const hasError = formRegister.supported ? Object.values(errors).includes(true) :  errors.email;
    const errorMessage = formRegister.supported ? t('validation.FROM_HAS_ERRORS') : 'A valid email is required';

    if (hasError) {
      notify(errorMessage, 'error');
    } else {
      this.setState({ applyLoading: true });
      query('POST', '/v1/organizations/signup', {
        cat: formRegister.cat,
        organizationName: formRegister.organizationName,
        country: formRegister.countryValue,
        state: formRegister.state,
        city: formRegister.city,
        address: formRegister.address,
        zipcode: formRegister.zipcode,
        phone: formRegister.phone,
        timezone: formRegister.timezone,
      }).then((json) => {
        if (json.success) {
          this.setState({
            firstScreen: true,
            finalScreen: true,
            formRegister: {
              organizationName: '',
              cat: '',
              catName: '',
              catChange: false,
              address: '',
              city: '',
              state: '',
              zipcode: '',
              timezone: '',
              countryChange: false,
              hasState: false,
              stateChange: false,
              zipcodeRegex: '',
              hasZipcode: false,
              hasTimezone: false,
              timezoneChange: false,
              supported: true,
            },
          });
          notify(t('register.CREATE'), 'success');
        } else {
          notify(t('register.SERVER_ERROR'), 'error');
        }
      });
    }
    this.setState({ applyLoading: false });
  };

  hasError = (formName, inputName, method) => {
    const { formRegister } = this.state;
    return formRegister
      && formRegister.errors
      && formRegister.errors[inputName]
      && formRegister.errors[inputName][method];
  }

  checkField(e) {
    const form = e.target;
    const inputs = [...form.elements].filter((i) => ['INPUT'].includes(i.nodeName) && i.name);
    const { errors, hasError } = FormValidator.bulkValidate(inputs);

    this.setState((prevState) => ({
      [form.name]: {
        ...prevState[form.name],
        errors,
      },
    }));
    return hasError;
  }

  getCategoriesList = (categoriesGroupId = '') => {
    const { t } = this.props;
    query('GET', `/v1/categories/${categoriesGroupId}/subcategories`)
      .then(async (json) => {

        if (json.success) {
          const catOptions = json.categories.map((item) => (item.localeKey !== null
            ? { value: item.uuid, label: t(item.localeKey) }
            : { value: item.uuid, label: item.name }));

          this.setState(() => ({
            catOptions,
          }));
        }
      });
  }

  async countryChange(value, event, option) {
    const { t } = this.props;
    const data = momentTZ.tz.zonesForCountry(value);
    let timezones = data.map((zone) => {
      const tz = momentTZ.tz(zone);
      const offset = tz.format('Z');
      const abbreviation = tz.zoneAbbr();
      const label = `(GMT ${offset}) ${zone} (${abbreviation})`

      return {
        label,
        value: zone,
        abbreviation,
        offset,
      }
    });
    timezones = timezones.sort((a,b) => a.label > b.label ? 1 : -1);

    const { success, country, states } = await query('GET', `/v1/countries/${value}`);

    const stateOptions = states.map((item) => (item.locale !== null
      ? { value: t(item.locale, item.name), label: t(item.locale, item.name) }
      : { value: item.name, label: item.name }));

    if (success) {
      const languages = availableLanguages.split(',');

      if (
        languages.includes(country.defaultLanguage)
        || languages.includes(getShortLocale(country.defaultLanguage))) {
        setLanguage(country.defaultLanguage);
      } else {
        setLanguage(defaultLanguage);
      }

      setDirectionClass(getDirection(value));

      const hasState = stateOptions.length !== 0
          && (country.useStates
            || country.useStatesInAddress
            || country.useStatesAbbrInAddress);
      const hasZipcode = country.zipcodeFormat !== null;

      this.setState((state) => ({
        timezones,
        stateOptions,
        firstScreen: false,
        formRegister: {
          ...state.formRegister,
          countryChange: true,
          cat: '',
          catChange: false,
          country: option.label,
          countryValue: value,
          timezone: country.defaultTimezone || data[0],
          hasTimezone: data.length !== 1,
          timezoneChange: false,
          supported: country.supported === 1,
          hasState,
          hasZipcode,
          zipcodeRegex: country.zipcodeRegex,
          stateChange: false,
        },
        errors: {...state.errors, state: hasState, zipcode: hasZipcode}
      }),
        await this.getCategoriesList(country.categoriesGroupId)
      );
    }
  }

  getCatValue(value, event, data) {
    const { formRegister, errors } = this.state;
    formRegister['cat'] = value;
    formRegister['catName'] = data.label;
    formRegister['catChange'] = true;
    errors['cat'] = false;
    this.setState({ formRegister, errors });
  }


  render() {
    const { account } = this.props;
    const {
      errors,
      firstScreen,
      finalScreen,
      formRegister,
      catOptions,
      stateOptions,
      countries,
      timezones,
      touched,
    } = this.state;
    const required = formRegister.supported ? ["required"] : [];

    if (account.hasEmployment) {
      return (
        <main className="main-content p-0">
          <div className="d-flex login-page">
            <div className="login-page__right-item order-1 order-md-0">
              <figure className="logo">
                <img src={logo} alt="" />
              </figure>
              <h3 className="title">
                <Trans i18nKey="without_active_organization.TITLE">Without Active Organization</Trans>
              </h3>
              <div className="text-center">
                <br />
                <Trans i18nKey="without_active_organization.TEXT">you does't have an Active Organization, please call your manager to give access your account correct permitions</Trans>

                <br />

                <LoadingButton
                  type="submit"
                  className="btn-login mt-1"
                  onClick={this.logout}
                >
                  <Trans i18nKey="header.LOGOUT">Log Out</Trans>
                </LoadingButton>
              </div>
              <br />
              <p className="footer-login"><Copyright /></p>
            </div>
            <div className="login-page__left-item">
              <img className="image" src={`${host}${placeholderSideImage}`} alt="" />
            </div>
          </div>
        </main>
      );
    }

    return (
      <main className="">
        <div className="d-flex login-page">
          <div className="register-page__right-item order-1 order-md-0">
            <figure className="logo">
              <img src={logo} alt="logo" />
            </figure>
            <h3 className="title">
              <Trans i18nKey="register.WELCOME">Welcome to Your Business Front</Trans>
            </h3>
            <p className="title2">
              <Trans i18nKey="register.TITLE">Sign Up</Trans>
            </p>
            {finalScreen ? (
              <div className="register-form">
                <div className="alert alert-info fade show text-center">
                  <h2>
                    <Trans i18nKey="register.FINAL_TITLE">Thank you so much</Trans>
                  </h2>
                  <br />
                  <Trans i18nKey="register.FINAL_SUBTITLE">One of our representatives will be in touch with you in one business day.</Trans>
                </div>
              </div>
            ) : (
              <form className="register-form" name="formRegister" onSubmit={this.onSubmitRegister}>
                <div className="mb-3 position-relative">
                  <Select
                    name="country"
                    id="country"
                    getValue={this.countryChange}
                    value={formRegister.countryValue}
                    options={countries}
                    label={<Trans i18nKey="register.COUNTRY">Country</Trans>}
                  />
                  {formRegister.errors && formRegister.errors.country && formRegister.errors.country.required && (
                    <Form.Control.Feedback type="invalid">
                      <Trans i18nKey="validation.REQUIRED">This field is required</Trans>
                    </Form.Control.Feedback>
                  )}
                </div>
                {!formRegister.supported && (
                  <div className="alert alert-warning fade show">
                    <Trans i18nKey="register.RESTRICTED_SERVICE">
                      Unfortunately, we are not in your country at the moment, but we are working on
                      it. if you let us have your email address, we will let you know as soon as we
                      come to your area. We never spam your email or sell it to anybody
                    </Trans>
                  </div>
                )}

                {!firstScreen && formRegister.supported && (
                  <div>
                    <br/>
                    <div className="mb-3 position-relative">
                      <h5>Business Information</h5>
                    </div>

                    <div className="position-relative mb-3">
                      <InputValidator
                        type="text"
                        id="organizationName"
                        name="organizationName"
                        validators={required}
                        getValue={this.validateOnChange}
                        value={formRegister.organizationName}
                        hasError={errors['organizationName'] && touched}
                        errorText={[
                          <Trans i18nKey="validation.REQUIRED">This field is required</Trans>
                        ]}
                        label={<Trans i18nKey="register.ORGANIZATION_NAME">Business Name</Trans>}
                      />
                    </div>

                    <div className="mb-3 position-relative">

                        <Select
                          name="cat"
                          id="cat"
                          getValue={this.getCatValue}
                          value={formRegister.cat}
                          options={catOptions}
                          label={<Trans i18nKey="register.CATEGORIES">Business Category</Trans>}
                        />
                      {errors && errors.cat && touched && (
                        <Form.Control.Feedback type="invalid">
                          <Trans i18nKey="validation.REQUIRED">This field is required</Trans>
                        </Form.Control.Feedback>
                      )}
                    </div>
                  </div>
                )}

                <div>
                  {!firstScreen && formRegister.supported && (
                    <div>
                      <div className="mb-3 position-relative">
                        <InputValidator
                          type="text"
                          id="address"
                          name="address"
                          validators={required}
                          value={formRegister.address}
                          getValue={this.validateOnChange}
                          hasError={errors['address'] && touched}
                          label={<Trans i18nKey="register.ADDRESS">Street Address</Trans>}
                          errorText={<Trans i18nKey="validation.REQUIRED">This field is required</Trans>}
                        />
                      </div>
                      <div className="mb-4 position-relative">
                        <InputValidator
                          id="city"
                          type="text"
                          name="city"
                          errorText={[
                            <Trans i18nKey="validation.REQUIRED">This field is required</Trans>
                          ]}
                          validators={required}
                          value={formRegister.city}
                          getValue={this.validateOnChange}
                          hasError={errors['city'] && touched}
                          label={<Trans i18nKey="register.CITY">City</Trans>}
                        />
                      </div>
                    </div>
                  )}
                </div>

                <div>
                  {!firstScreen
                    && formRegister.supported
                    && formRegister.hasState &&
                    <div className="position-relative mb-3">
                      <section className="">
                        <Select
                          name="state"
                          id="state"
                          getValue={(value) => this.setState({
                            formRegister: {
                              ...formRegister,
                              state: value,
                              stateChange: true,
                            },
                            errors: {...errors, state: false}
                          })}
                          value={formRegister.state}
                          options={stateOptions}
                          label={<Trans i18nKey="register.STATE">State</Trans>}
                        />
                      </section>
                      {errors && errors.state && touched && (
                        <Form.Control.Feedback type="invalid">
                          <Trans i18nKey="validation.REQUIRED">This field is required</Trans>
                        </Form.Control.Feedback>
                      )}
                    </div>
                  }
                </div>

                {!firstScreen
                  && formRegister.supported
                  && formRegister.hasZipcode && (
                    <div className="mb-3 position-relative">
                      <InputValidator
                        id="zipcode"
                        name="zipcode"
                        maxLength="12"
                        // format="#####-#####"
                        validators={required}
                        value={formRegister.zipcode}
                        getValue={this.validateOnChange}
                        hasError={errors['zipcode'] && touched}
                        errorText={<Trans i18nKey="validation.REQUIRED">This field is required</Trans>}
                        label={<Trans i18nKey="register.ZIPCODE">Zip Code</Trans>}
                      />
                    </div>
                  )}

                {!firstScreen
                  && formRegister.supported
                  && formRegister.hasTimezone && (
                    <div className="mb-3 position-relative">
                      <div className="with-focus">
                        <Select
                          name="timezone"
                          id="timezone"
                          getValue={(value) => this.setState({
                            formRegister: {
                              ...formRegister,
                              timezone: value,
                              timezoneChange: true,
                            },
                          })}
                          value={formRegister.timezone}
                          options={timezones}
                          label={<Trans i18nKey="register.TIMEZONE">Time Zone</Trans>}
                        />
                      </div>
                      {formRegister.errors && formRegister.errors.timezone && (
                        <div className="error-alert">
                          <Trans i18nKey="validation.REQUIRED">This field is required</Trans>
                        </div>
                      )}
                    </div>
                  )}

                <br/>

                {
                  !firstScreen &&
                  <LoadingButton type="submit" loading={this.state.applyLoading}>
                    {
                      formRegister.supported ? (
                        <Trans i18nKey="register.CREATE_ACCOUNT">Sign Up</Trans>
                      ) : (
                        <Trans i18nKey="register.REQUEST_ACCOUNT">Request Service</Trans>
                      )}
                  </LoadingButton>
                }

                 <LoadingButton
                  type="submit"
                  className="btn-login m-3"
                  onClick={this.logout}
                >
                  <Trans i18nKey="header.LOGOUT">Log Out</Trans>
                </LoadingButton>

                <br/>
                <br/>
                <hr/>

              </form>
            )}
            <p className="footer-register"><Copyright /></p>
          </div>
          <div className="register-page__left-item">
            <img className="image" src={`${host}${sideImage}`} alt="" />
          </div>
        </div>
      </main>
    );

  }
}

WithoutActiveOrganization.propTypes = {
  t: PropTypes.func,
  account: PropTypes.shape().isRequired,
};

WithoutActiveOrganization.defaultProps = {
  t: () => { },
};

const mapDispatchToProps = (dispatch) => ({
  onLogout: () => dispatch({ type: action.LOGOUT_USER }),
});

const mapStateToProps = (state) => ({
  account: state.accountReducer,
});

export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces('translations')(WithoutActiveOrganization));


