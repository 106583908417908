import query from './query';

function send(priority, log) {
  const logOpt = log;
  logOpt.priority = priority;
  logOpt.environment = 'web';

  query('POST', '/v1/logs', logOpt, false);
}

const info = (...rest) => {
  // eslint-disable-next-line no-console
  console.info(...rest);
};
const log = (...rest) => {
  // eslint-disable-next-line no-console
  console.log(...rest);
  send('log', rest);
};
const warn = (...rest) => {
  // eslint-disable-next-line no-console
  console.warn(...rest);
  // TODO enable sending the warnings after fixing the moment
  // send('warn', rest)
};
const error = (...rest) => {
  // eslint-disable-next-line no-console
  console.error(...rest);
  send('error', rest);
};

const logger = {
  info,
  log,
  warn,
  error,
};

export default logger;
