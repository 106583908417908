import { toast } from 'react-toastify';
import { MD5 } from 'crypto-js';
import { getSupportedLocales } from './localeTools';
import moment from './moment';

export function hasBodyClass(className) {
  return document.body.classList.contains(className);
}

export function addBodyClass(className) {
  if (!hasBodyClass(className)) {
    document.body.classList.add(className);
  }
}

export function removeBodyClass(className) {
  if (hasBodyClass(className)) {
    document.body.classList.remove(className);
  }
}

export function setLanguageClass(className) {
  const locales = getSupportedLocales();
  for (let index = 0; index < locales.length; index += 1) {
    removeBodyClass(locales[index]);
  }

  addBodyClass(className);
}

export function setDirectionClass(className) {
  if (className === 'ltr') {
    addBodyClass('ltr');
    removeBodyClass('rtl');
    toast.configure({ rtl: false });
  } else if (className === 'rtl') {
    addBodyClass('rtl');
    removeBodyClass('ltr');
    toast.configure({ rtl: true });
  }
}

export const notify = (msg = 'Error!', type1 = 'error', position1 = 'top-right') => {
  toast(msg, {
    type: type1,
    position: position1,
  });
};

export function padWithZeroes(number, length) {
  let myString = `${number}`;

  while (myString.length < length) {
    myString = `0${myString}`;
  }
  return myString;
}

export function getGravatar(email = '') {
  const avatar = '/images/avatar.png';
  if (!email) {
    return avatar;
  }

  email = email.trim().toLowerCase();
  const md5 = MD5(email).toString();
  return `https://secure.gravatar.com/avatar/${md5}?s=40&d=mp`;
}

export function getMinutes(time = '00:00') {
  const splitHour = time.split(':');
  return parseInt(splitHour[0], 10) * 60 + parseInt(splitHour[1], 10);
}

export function getHours(seconds = 0) {
  let hours = Math.floor(seconds / 60);
  let minutes = Math.floor(seconds % 60);
  if (hours < 9 && hours >= 0) {
    hours = `0${hours}`;
  }
  if (minutes < 9 && minutes >= 0) {
    minutes = `0${minutes}`;
  }
  return `${hours}:${minutes}`;
}

export function getMonthsFormat({ calendar, monthName }) {
  return Array.apply(0, Array(12)).map((_, i) => {
    if (calendar === 'jalali') {
      return moment().jMonth(i).format(monthName);
    }
    if (calendar === 'hijri') {
      return moment().iMonth(i).format(monthName);
    }
    return moment().month(i).format(monthName);
  });
}

export function getDaysFormat({ month }) {
  const days = [];
  let limitDay = 31;
  if (month > 6) {
    limitDay = 30;
  }
  for (let day = 1; day <= limitDay; day += 1) {
    days.push(padWithZeroes(day, 2));
  }

  return days;
}

export function toDateFormat(timestamp) {
  return moment().diff(timestamp * 1000, 'days');
}

export function getModules(account) {
  const modules = account.organization && account.organization.modules
    ? account.organization.modules
    : '';
  if (modules) {
    return modules.replace(/ /g, '').split(',');
  }
  return [];
}
