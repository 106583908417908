import jstz from 'jstz';
import * as action from '../actions/action';
import { getLanguage, setLanguage } from '../../utils/localeTools';
import { setInStorage } from '../../utils/storage';

const initialState = {
  isLoggedIn: false,
  defaultRoute: null,
  user: {},
  branch: {},
  organization: {},
  employment: {},
  permissions: {},
  messages: [],
  xToken: null,
  errAlert: '',
  hasEmployment: false,
};

const reducer = (state = initialState, payload) => {
  let { defaultCalendar } = payload.user || 'gregorian';
  const { defaultLanguage } = payload.user || 'en-US';
  switch (payload.type) {
    case action.LOGIN_USER:
      // localization
      if (defaultCalendar === undefined) {
        defaultCalendar = 'gregorian';
      }
      if (defaultLanguage !== getLanguage()) {
        setLanguage(defaultLanguage);
      }
      // timezone
      let timezone;
      if(payload.branch) {
        timezone = payload.branch.timezone;
      } else {
        const timezoneDetermine = jstz.determine()
        timezone = timezoneDetermine.name();
      }
      setInStorage('timezone', timezone);
      setInStorage('calendar', defaultCalendar);
      return {
        ...state,
        isLoggedIn: payload.isLoggedIn,
        defaultRoute: payload.defaultRoute,
        user: {
          ...payload.user,
          defaultCalendar,
        },
        branch: payload.branch,
        organization: payload.organization,
        employment: payload.employment,
        permissions: payload.permissions,
        messages: payload.messages,
        xToken: payload.xToken,
        hasEmployment: payload.hasEmployment
      };

    case action.UPDATE_USER:
      if (defaultCalendar === undefined) {
        defaultCalendar = 'gregorian';
      }
      setInStorage('calendar', defaultCalendar);
      return {
        ...state,
        user: {
          ...payload.user,
          defaultCalendar,
        },
        branch: payload.branch,
        organization: payload.organization,
      };

    case action.STORE_TOKEN:
      return {
        ...state,
        xToken: payload.xToken,
      };

    case action.LOGOUT_USER:
      return {
        isLoggedIn: false,
        xToken: null,
        user: [],
        errAlert: '',
      };

    default:
      return state;
  }
};

export default reducer;
