import React from 'react';
import PropTypes from 'prop-types';
import BootstrapProgressbar from 'react-bootstrap/ProgressBar';

const COLOR = ['primary', 'secondary', 'success', 'warning', 'danger', 'info', 'dark', 'light'];

export default function Progressbar(props) {
  const { striped, color, value } = props;

  return <BootstrapProgressbar striped={striped} variant={color} now={value}/>;
}

Progressbar.propTypes = {
  value: PropTypes.number,
  striped: PropTypes.bool,
  color: PropTypes.oneOf(COLOR),
  className: PropTypes.string,
};

Progressbar.defaultProps = {
  color: 'primary',
};
